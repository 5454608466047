import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const IndexPage = ({ data }) => (
  <Layout transparentNav>
    <SEO title="Home" />

    <div className="max-w-6xl m-auto">
      <h3
        className="text-white text-center text-lg sm:text-xl"
        style={{ marginTop: "20vh" }}
      >
        April 6, 2024
      </h3>
      <h1 className="text-white text-center font-bold text-4xl sm:text-6xl">
        South Bay MUN VIII
      </h1>

      <div className="text-center mt-12">
        <Link
          className="btn btn-purple text-lg mr-6 sm:text-xl sm:mr-10"
          to="/register"
        >
          Register Now
        </Link>
        <a
          className="btn btn-purple-inverse text-lg sm:text-xl"
          href="mailto:southbaymun@gmail.com"
        >
          Contact Us
        </a>
      </div>
    </div>

    <div className="absolute bottom-0 left-0 right-0 top-0 -z-10 overflow-hidden">
      <Img
        fluid={data.bgImage.childImageSharp.fluid}
        className="h-screen"
        style={{ minHeight: "800px" }}
      />
    </div>
  </Layout>
)

export const query = graphql`
  query {
    bgImage: file(relativePath: { eq: "header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
